import React from "react"
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Whoweare(){
 return(
    <>
      <img src="/images/slide (2).png"></img>

    <h1 className='eych2 text-center'>Canadian Mining Expo</h1>

    <div className='frdiv'>
        <p>Canadian Mining Expo held in Timmins, Ontario will take place the first week of June. Starting on Tuesday June 4th the event will kick off with the Gala Dinner, followed the next day with the trade show.The trade show features over 400+ displays showcasing the latest innovations, products and service. Attending Canadian Mining Expo within Canada's most profilic mining jurisdications is jam packed with activities from conferences to networking sessions. The event places the attendee at the epicenter of all activities within the mining sector. If you want to rub shoulders and speak to the people who work directly in the Canadian mining sector, then CME is a must attend event. </p>
      </div>

    <div className='frdiv'>
        <p>
          Do not be disappointed book your booth and sponsorship early. To participate as an exhibitor you can review the maps on <a className="naymlink">Canadian Mining Expo.</a>  Feel free to give us a call at 705-264-2251 if you have any questions.</p>
      </div>

      


      <img variant="top" src="images/cmeweb.jpg" />
   

  

 </>
 )
} 