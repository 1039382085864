import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import React from "react"
import './Pages.css';


export default function ContactUs(){
    return(
       <>

      <h1 className='eych2 text-center'>Contact Us</h1>

      <div className="card p-4 m-3">
      <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name </Form.Label>
        <Form.Control type="email" required/>
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" required/>
        <Form.Label>Telephone Number</Form.Label>
        <Form.Control type="email" placeholder="+1 (123) 456-7890" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={9} />
      </Form.Group>
    </Form>
      
        <button className="btn1">Send</button>

    </div>

    <div className='frdiv'>
        <p>CT-EX 157 Algonquin Blvd West, Timmins, <br></br>
         On P4N 2R6</p>
      </div>
    <div className='frdiv'>
        <p>Mailing Address: P0 Box 490 Schumacher, <br></br>
         On P0N 1G0</p>
      </div>
    <div className='frdiv'>
        <p>Toll-Free: 866-754-9334</p>
      </div>
    <div className='frdiv'>
        <p>Ph: 705-264-2251 <br></br>
         Fax: 705-264-4401 <br></br>
         Email: info@canadiantradex.com</p>
      </div>
    </>
 )
}