import React from "react"
import { Link } from 'react-router-dom';
import './MyFooter.css';


export default function MyFooter(){
    return(
    <>
   <div className="bgfoot text-center">

    <div>
      <p className="parafoot">© 2013 CT-EX. All Rights Reserved</p>
      <img src="images/foot.png" height={50}></img>
      <p className="parafoot">This website developed with the assistance from NEOnet Inc</p>
    </div>

    <div>
      <p className="parafoot">CT-EX 157 Algonquin Blvd West, Timmins, On P4N 2R6 <br/>
        Mailing Address: P0 Box 490 Schumacher, On P0N 1G0 <br/>
        Ph: 705-264-2251 - Fax: 705-264-4401 <br/>
        Email: Tradex@onlink.net</p>
    </div>

   </div>



    </>
)}