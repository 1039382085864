import React from "react"
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Whoweare(){
 return(
    <>
    <h1 className='eych2 text-center'>Who we are</h1>

    <div className='frdiv'>
        <p>Canadian Trade-Ex is a media company, we create different venues that have value to the mining audience that we serve. A brief summary of some of the venues included below:</p>
      </div>


    <div className='frdiv'>
        <ul>

         <li>Canadian Mining and Exploration News is a quarterly publication that produces in depth articles covering mining related activities and operations from Central Canada.</li>
         <li>Mining Life Online is a portal that connects the mining community. The portal's many features were purposely built to be used as a resource for all connected to the industry</li>
         <li>Tradeshows and Conferences: We host two physical events that focus on mining activities within their regions. These events are excellent opportunities allowing individuals connected to production to speak with suppliers of products and services.</li>
         <li>Virtex is our virtual platform that leverages web technologies connecting the mining community across the globe.</li>

        </ul>
      </div>

      <div className='frdiv pb-4 pt-3'>
        <p>For a detailed overview of each venue click on the links below.</p>
      </div>

   <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/box (3).png" />
    </Card>

    </div>

   <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/box (1).png" />
    </Card>

    </div>
 </>
 )
} 