import React from "react"
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Ourfavorite(){
 return(
    <>
    <h1 className='eych2 text-center'>Mining Portal</h1>

      <div className='cards'>

      <Card style={{ width: '22rem' }}>
        <Card.Img variant="top" src="images/box (3).png" />
      </Card>

      </div>

      <div className='frdiv pb-4 pt-3'>
        <p>In an era where your challenge is to show differentiation, “Presentation” has never been more important. How Mining Life Online Enhances Your Company’s Brand.</p>
        <p>Mining life Online was created to provide a platform for all those connected to the Mining Sector. One Location -one website. Whether your interests is the latest in news, events or reading industry specific blogs or forums that include the latest in mining Technology; Mininglifeonline will keep your finger on the pulse of an industry. You can expect more from the portal then aggregation of news. The portals design was purposefully built to engage the community and provide a dynamic home that will grow to be an authoritative and referenced voice of the community.</p>
      </div>

    <div className="frdiv">
      <p><strong className="bold"><i>Your Brand will be Enhanced</i></strong> with the use of the following tools:</p>
    </div>

    <div className='frdiv'>
        <ol>

         <li>Interactive Maps, that identify projects within geographic areas. Icons will identify your location and link the user to a page that highlights the projects specifics.</li>
         <li>As a member your company will be able to add content from news, analyst coverage, videos, images, career opportunities, financials, RFP from suppliers.</li>
         <li>Features, that have been added to enhance your users experience:</li>
         <ul>
          <li>The ability to tag your content to allow for easy search and audience retrieval.</li>
          <li>Event application allows your company to post and publish upcoming Events. Event engine will database list of attendees, send out automatic reminders, creation of tickets that include barcodes, pre-approval options for attendees, ability to connect to social platforms (facebook, twitter email)</li>
          <li>Hosting an online broadcast, coupled with the technology above your event could include a live webinar. Make your “Pitch” to an audience of sophisticated investors and allow the audience to engage the presenter.</li>
          <li>Reports within the Mining Portal can be presented in a custom application that will allow your readers to bookmark, share, email pages, view video and audio files from the document and link to both internal and external pages.</li>
          <li>Enhanced Visualization with the use of animated productions that combine images, video and CGI graphics. Request a Quote.</li>
         </ul>
      
        </ol>
      </div>

      <h1 className='eych2 text-center'>A Peek into the NEAR FUTURE: “Game Breaker Tools For your Business”</h1>

      <div className="frdiv">
      <p><strong className="bold"><i>Virtual Investment Forum</i></strong>: Bring the world of institutional and sophisticated investors to your office. Introducing the newest in Dog and Pony Shows that leverage web-technology. For 20 years we have been producing B2B trade events that get results. Mining Life Online has developed its own Webinar platform and are taking steps to integrate this technology. Once completed our platform will be capable of broadcasting virtual trade shows, that are specific to the Junior Resource Community.<strong className="bold">Stay Tuned.</strong> </p>
    </div>

    <hr></hr>

    <a className='click1 text-center'><p>Visit Mining Life Online</p></a>
    <a className='click1 text-center'><p>Click here to see the list of programs</p></a>
    <a className='click1 text-center'><p>Promote your company using Banner System</p></a>

    <div className='frdiv pt-3'>
      <h5>Why Mining Life Online Rocks!!!</h5>
        <p>We built an administrative interface that makes it simple to add content. Whether you would like to add images, change product descriptions or update the latest videos, our intuitive backend is point and click simple. Looking to advertise or promote your latest innovation, our events and banner advertising schemes are a breeze, tutorials will guide you or simply click for help and our support staff will walk you through the process.</p>
      </div>


  <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/banner3.png" />
    </Card>s

    </div>
 </>
 )
} 