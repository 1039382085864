import React from "react"
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Whoweare(){
 return(
    <>
    <h1 className='eych2 text-center'>Our history</h1>

    <div className='frdiv'>
        <p>We have been producing trade shows, conferences,Industry trade journals for the last 25 years.  Our beginnings started in the Forest Industry , in the mid-80's our trade events were instrumental in introducing mechanical harvesting to Northern Ontario and Northwestern Quebec.  Our events transformed and brought about fundamental changes to the forestry community.  For the last 10 years our organization has been increasing our companies positrion with the Mining Sector.  We have produced Canada's Largest Gold Mining Show in TImmins and have started a similiar path for Sudbury's North America Mining Expo.</p>
      </div>


      <div className='frdiv'>
        <p>We have worked with a diverse group of organizations, that many ways mirror our own communities.  Our clients make their living from the resources that surround us, and we highlight the components that make up the whole.  Whether you are reviewing a web portal page or attending a trade show event the stories and people will be representative of the communities that we affect.</p>
      </div>

      <div className='frdiv'>
        <p>Over the years we have worked with many organizations, liste below are some who we have been privielged to form working relationships including partnerships:</p>
      </div>
    
      {/* <div className='cardss text-center'>
        <img variant="top" src="images/logoss.png" height={80}/>
        <img variant="top" src="images/logoss1.png" height={80}/>
      </div>
      <div className='cardss text-center pt-2'>
        <img variant="top" src="images/logoss3.png" height={60}/>
        <img variant="top" src="images/logoss4.png" height={60}/>
      </div>
      <div className='cardss text-center pt-4 mb-5'>
        <img variant="top" src="images/logoss5.png" height={40}/>
        <img variant="top" src="images/logoss6.png" height={60}/>
      </div> */}



   <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/box (1).jpg" />
    </Card>

    </div>

   <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/box (1).png" />
    </Card>

    </div>
 </>
 )
} 