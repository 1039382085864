import React from "react"
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Conferences(){
 return(
    <>
    <h1 className='eych2 text-center'>Consulting</h1>

    <div className='frdiv'>
      <p><strong>Rent our Services:</strong></p>
        <p>Canadian Trade-ex has been fortunate to work with a number of communities, organizations from the most Western part of Ontario to North-Western Quebec and many communities in between over the last 25 years.  Our success can be attributed to our small town values, where we understand that our job directly impacts communities, families and industry sectors. It is a level of accountability that is shared by each and every member of our Team.  We have never been big on self-promotion or any type of self-endorsement, we would rather show by example and lead by doing.  Our motto we are “doers, that will get the job done” a simple statement that reflects who and what we are all about.</p>
      </div>

      <div className="frdiv">
        <p><strong>Enclosed is a quotation from the Fort Francis Times that sums up our ability.</strong></p>
      </div>

      <div className="px-3">
 
        <p><i>Saw Tech Log Expo Northwest 2009 Fort Francis “You would have never have known that the forestry industry is falling apart at the seams with the turn-out, at the Memorial Sports Center, Fort Francis this past weekend.  Sawtechlogexpo Rural Northwest was a success. Glenn Dredhart show organizer with Canadian Trade-ex pulled a rabbit out of the hat.” </i></p>
      </div>      


        <div className='frdiv'>
        <p>Regardless of our location or the industry that we are serving, our Team will work tirelessly on your behalf producing a product/service that will exceed your expectations.  We would be honored to speak to your organization about how our services and how they can benefit your group.</p>
        
      </div>

      <div className='frdiv pb-4 pt-3'>
        <p>If you have any questions please do not hesitate to fill in the form below and a representative will call you shortly.</p>
      </div>

      <div className="card p-4 m-3">
      <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name </Form.Label>
        <Form.Control type="email" required/>
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" required/>
        <Form.Label>Telephone Number</Form.Label>
        <Form.Control type="email" placeholder="+1 (123) 456-7890" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
    </Form>

    <p>Services Interested. Check all services that you may are interested.</p>

    <Form>
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Conferences ${type}`}
            className="custom-label" 
          />
        </div>
      ))}
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Events ${type}`} className="custom-label" 
          />
        </div>
      ))}
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Set Up Events ${type}`} className="custom-label" 
          />
        </div>
      ))}
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Consulting ${type}`} className="custom-label" 
          />
        </div>
      ))}
    </Form>
      
        <button className="btn1">Send</button>

    </div>

   <div className='cards mt-5'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/banner1.png" />
    </Card>

    </div>

   <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/banner2.png" />
    </Card>

    </div>
 </>
 )
} 