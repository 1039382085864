import React from "react"
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function OurStaff(){
 return(
    <>
    <h1 className='eych2 text-center'> Our Team</h1>

    <div className='allcontacts'>
      
    <p className='cardtitle1'>Glenn Dredhart President</p>
    <p className='cardtext1'>President of Canadian Trade-Ex (CT-EX), Publisher of Mining Life & Exploration News, President of mininglifeonline.net</p>
    <p className='cardtext1'>Glenn has recieved from The City of Timmins Economic Development and North Eastern Ontario Catholic School Board Certificates of Recognition for for his contribution to the Economic Development and Placement of students within the community.</p>
    

    <p className='cardtitle1'>Audrey Guindon Vice President</p>
    <p className='cardtext1'>Audrey over sees the administrative side of CT-EX operations. She works closely with Glenn ensuring that all of the assets that the company produces delivers the highest standards within the industry. She strives to continously improve and make each event, publication, conference better then the previous.</p>


    <p className='cardtitle1'>Rhonda Larochelle Sales Rep/Director Event Activity</p>
    <p className='cardtext1'>Rhonda is a sales representative and has been involved in this role for 18 years. When Rhonda is not serving her clients, she will be busy organizing many of the activities of our physical events from conferences to networking sessions,</p>

    <p className='cardtitle1'>John Goulet Sales Rep/Event Set Up Director</p>
    <p className='cardtext1'>John has a diverse background, he is a graduate of Northern College with a diploma in Business. John also has 15 years experince working the exploration drill rigs and working underground as a production miner. For the last 20 years, he has been a sales agent with Canadian Trade-Ex and oversees the preparation and tear down of all of our physical events.</p>

    <p className='cardtitle1'>Kevin Vincent</p>
    <p className='cardtext1'>Kevin heads our editorial department providing in depth articles for Canadian Mining Exploration News and curating localized mining news for Mining Life Online. Kevin has been involved in the the mining sector since 1988, where he had the opportunity to research hundreds of mining and general interest articles on topics like the Ring of Fire for a variety of magazines and newspapers acorss Canada including: The Globe and Mail, The Toronto Star, The Northern Miner, Canadian Mining Life and Exploration News and CBC Northern Ontario radio (mostly from 1978- 1984).</p>

    <p className='cardtitle1'>Rory Dredhart CEO of ClearSky Software</p>
    <p className='cardtext1'>Rory is the CEO of ClearSky Software a boutique software engineering company. Canadian Trade-Ex has a unique relationship with ClearSky, it is tthrough this relationship that has allowed CT-EX to integrate proprietary software solutions that deepen our relationship with the Mining community.  </p>

    <p className='cardtitle1'>Larry Sutton Sales Representative</p>
    <p className='cardtext1'>Larry is a sales representative and has come out of retirement (4 years ago ) to join the Canadian Trade-Ex sales team. Prior to joining Canadian Trade-ex, Larry worked as a representative for the Canadian Federation of Independent Business advocating for Northern Ontario small businesses.</p>

    </div>

    <h1 className='eych2 text-center'>If you want to join our team</h1>
    <h1 className='eych22 text-center'>Call us 705-264-2251</h1>
 </>
 )
} 