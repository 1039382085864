import React from "react"
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Conferences(){
 return(
    <>
    <img src="/images/slide (6).png"></img> 

    <h1 className='eych2 text-center'>Virtual Events</h1>

    <div className='frdiv'>
        <p>Canadian Trade-Ex introduced Virtex, our virtual online platform a few years ago. It was developed in response to the pandemic, allowing the mining community a central location where they could come together. In 2021 and 2022 we hosted two virtual expos. In polling our attendees, they identified the following key benefits in their participation: </p>
      </div>
    <div className='frdiv'>
        <p>have organized a number of conferences and are prepared to work with an organization ensuring your conference delivers and exceeds all expectations.  Our process breaks down your event into the following headlines:  </p>
      </div>


    <div className='frdiv'>
        <ul>

         <li>The Virtual Expo addresses a company’s supply chain goals, namely: Connecting with a diversified list of suppliers.</li>
         <li>HR staff liked the opportunity to meet applicants globally.</li>
         <li>Procurement liked the opportunity of meeting global suppliers.  </li>
         <li>Suppliers liked the convenience and cost savings.</li>
         <li>Accessibility for all attendees, no traveling, no hotels.</li>
         <li>All attendees enjoyed the built in engagement opportunities to meet other attendees and exhibitors. Unique to our platform was a permission based registration process. We introduced various levels when registering for the event. Each level would allow different access, ex. an enhanced user would be able to view content and network with all attendees, while a job seeker would only be able to network and view content that was relevant to jobs.</li>
         
        </ul>
        <p>Moving forward Canadian Trade-Ex will be reaching out to other geographic mining regions where we will be introducing our digital assets including Virtex our virtual plaftorm. </p>

      </div>

   <div className='cards mt-5'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/box (1).png" />
    </Card>

    </div>
 </>
 )
} 