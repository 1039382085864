import React from "react"
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Ourfavorite(){
 return(
    <>
    <h1 className='eych2 text-center'>Mining Magazine</h1>

      <div className='cards'>

      <Card style={{ width: '22rem' }}>
        <Card.Img variant="top" src="images/box (2).png" />
      </Card>

      </div>

      <div className='frdiv pb-4 pt-3'>
        <p><strong>Mining Life and Exploration News: </strong></p>
        <p>Since 1994 Mining Life & Exploration News has offered a unique opportunity to gain maximum market perntration within Ontario, and Quebec’s Mining Sector.  We publish 4 times a year, readers can enjoy quality equipment and industry news updates, along with fresh local news.  Delivering stories on Northern Ontario and Quebec’s mining activities is our strength.</p>
      <a className="click">FALL 2016 MEDIA KIT INFO </a>
      </div>

    <div className="px-3">
    <Table className="" striped bordered hover size="sm">
        <thead><strong className="bold">Readers Composition</strong></thead>
      <tbody>
        <tr>
          <td>CEO/Manager, Project Managers,<strong> 24%</strong> </td>
        </tr>
        <tr>
          <td>Superintendents, Buyers, Supervisors <strong> 27%</strong></td>
        </tr>
        <tr>
          <td>Chief Geologist/Engineer <strong> 20%</strong> </td>
        </tr>
        <tr>
          <td>Investors  <strong> 12%</strong> </td>
        </tr>
        <tr>
          <td>Business Owners, Suppliers <strong> 17%</strong> </td>
        </tr>
      </tbody>
    </Table>

      <Table className="" striped bordered hover size="sm">
        <thead> <strong className="bold">Readers Nature of Business:</strong></thead>
      <tbody>
        <tr>
          <td>  Mining Companies   <strong> 30%</strong> </td>
        </tr>
        <tr>
          <td>Juniors & Exploration Companies <strong> 20%</strong></td>
        </tr>
        <tr>
          <td>Investors, Government <strong> 24%</strong> </td>
        </tr>
        <tr>
          <td>Investors  <strong> 24%</strong> </td>
        </tr>
        <tr>
          <td>Other <strong> 2%</strong> </td>
        </tr>
      </tbody>
    </Table>
    </div>
     
    <div className='frdiv pb-4'>
        <p>Circulation includes the top 100 Mining Companies and the top 200 Juniors and all Government Mining Ministries and Bureaus.</p>
      </div>
   
    <div className='frdiv pb-4'>
        <p>Moving Forward: </p>
      </div>
   
    <div className='frdiv pb-4'>
        <p>We live in a connected world, recognizing that some of our readership prefers reading our articles over their electronic devices. We will be introducing a digital version of Mining Life and Exploration News. The digital version Mining Life will make use of the platform by introducing regional publications in other mining juridiscations.</p>
      </div>
   
      <div className='cards'>

      <Card style={{ width: '22rem' }}>
        <Card.Img variant="top" src="images/box (1).jpg" />
      </Card>

      </div>

    <div className='cards'>

      <Card style={{ width: '22rem' }}>
        <Card.Img variant="top" src="images/box (1).png" />
      </Card>

      </div>
    <div className='cards'>

      <Card style={{ width: '22rem' }}>
        <Card.Img variant="top" src="images/box (3).png" />
      </Card>

      </div>

 </>
 )
} 