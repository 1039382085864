import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
  import { Link } from 'react-router-dom';


export default function Home(){
    return(
      <>
      <Carousel>
      <Carousel.Item>
        <img src='/images/slide (1).png'></img> 
      </Carousel.Item>
      <Carousel.Item>
        <img src='/images/slide (2).png'></img>  
      </Carousel.Item>
      <Carousel.Item>
        <img src='/images/slide (3).png'></img> 
      </Carousel.Item>
      <Carousel.Item>
        <img src='/images/slide (4).png'></img> 
      </Carousel.Item>
      <Carousel.Item>
        <img src='/images/slide (5).png'></img> 
      </Carousel.Item>
      <Carousel.Item>
        <img src='/images/slide (6).png'></img> 
      </Carousel.Item>
    </Carousel>

    <div>
      <div className='frtitle mt-3 mb-3'>
        <h1>Connecting Companies and Communities to Their Audience</h1>
      </div>

      <div className='frdiv'>
        <p>Canadian Trade-Ex for the last 30 years has been connecting the Mining sector in Central Canada, with physical events and a quarterly publication. As the internet evolved we expanded our reach by introducing Mining Life Online and when we were hit by the pandemic we again innovated by developing and integrating a Virtual platform that allowed Canadian Trade-Ex to host two virtual events Mining the Abitibi and Mining the Northwest.
        Moving forward we are committing to growing our audience of mining operations and suppliers. We have been focusing on enriching our current database by building relationships via social platforms and delivering content and value through our Online Portal and Virtual platform. We will use these platforms by strategically hosting events in mining locations across the globe, plans are in the works to add an event or two in South America.</p>
      </div>

    </div>

    <div className='text-center p-3'>
      <img src='images/Intro.png' height={120}></img>
    </div>

    <h5 className='text-center eych3'>We are Channel Experts who have honed our ability to Communicate to our Audience in digital age.</h5>


      <hr className='mx-5'></hr>


    <h1 className='eych2 text-center'>Our Expertise</h1>
    
    <div className='cards'>

    <Card style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/expert1.png" />
    </Card>

    </div>

    <p className='frdiv1'>We have been connecting the Mining and Forestry sectors together for 30 years. We understand that the events we produce provide a networking opportunity that enriches all involved. In all of our events we continuously strive to ensure the highest levels of technology and innovation are present.</p>

     <div className='cards'>

    <Card style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/expert3.png" />
    </Card>

    </div>

    <p className='frdiv1'>Mining Life Online was designed to be a resource portal for those that are connected to the mining sector. The platform will contain purposeful features that will ensure the portal becomes a working tool for those that work in the industry. It will showcase products and services, news, events and presentations. We will leverage digital technologies to permit networking and the transferring of information.Mining Life Online will be subscription based tool that will keep your finger on the pulse of an industry.</p>

     <div className='cards'>

    <Card style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/expert4.png" />
    </Card>

    </div>

    <p className='frdiv1'>Canadian Mining Life and Exploration News a quarterly magazine has covered Central Canada’s Mining Sector news for 30 years. Moving forward, we will be introducing a digital version of our existing magazine and creating new localized versions that will report on Mining news from different global areas. The new digital publication will provide our new readers with localized content that will also keep them current with mining best practices, technologies, trends, and reports.</p>

     <div className='cards'>

    <Card style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/expert2.png" />
    </Card>

    </div>

    <p className='frdiv1'>During the last 20 years our team has developed expertise in hosting conferences, trade shows, large scale banquets, and we are availing this skillset & technology to Organizations so that they could impact their own communities.</p>

    <hr className='mx-5'></hr>

    <h1 className='eych2 text-center'>Our major projects</h1>

    <div className='cards'>
    <Card className='card' style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/box (1).jpg"/>
      <Card.Body>
        <Card.Title className='cardtitle'>The Big Event</Card.Title>
        <Card.Text  className='cardtext'>
        The hottest gold mining properties in Canada have been showing up in or around Timmins for the last 10 years. During this time period promising exploration projects have come full circle and or now mines going into production. Canadian Mining Expo
        </Card.Text>
      </Card.Body>
    </Card>
    
    </div>  


    <div className='cards'>
    <Card className='card' style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/box (1).png"/>
      <Card.Body>
        <Card.Title className='cardtitle'>N.A.M.E. North America Mining Expo. Sudbury 2014</Card.Title>
        <Card.Text  className='cardtext'>
        Sudbury has a century of history as a mining centre and over a dozen mines operating within city limits. Over the past decade, the Northern Ontario city has been subtly shifting its focus from being a producer of metals to a creator of mining know-how and technology. North America Mining Expo
        </Card.Text>
      </Card.Body>
    </Card>
    
    </div>  


    <div className='cards'>
    <Card className='card' style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/box (2).png"/>
      <Card.Body>
        <Card.Title className='cardtitle'>Mining Life and Exploration news</Card.Title>
        <Card.Text  className='cardtext'>
        Since 1994 Mining Life & Exploration News has offered a unique opportunity to gain maximum market penetration within Ontario and Quebec’s Mining Sector. We publish 4 times a year. Readers can enjoy quality equipment and industry news updates, along with fresh local news. Delivering stories on Northern Ontario and Quebec’s Mining Activities is our strength.
        </Card.Text>
      </Card.Body>
    </Card>
    
    </div>  


    <div className='cards'>
    <Card className='card' style={{ width: '20rem' }}>
      <Card.Img variant="top" src="images/box (3).png"/>
      <Card.Body>
        <Card.Title className='cardtitle'>Mining Portal</Card.Title>
        <Card.Text  className='cardtext'>
        Mining life Online was created to provide a platform for all those connected to the Mining Sector. One Location -one website. Whether your interests is the latest in news, events or reading industry specific blogs or forums that include the latest in mining Technology; Mininglifeonline will keep your finger on the pulse of an industry. Mining Life Online
        </Card.Text>
      </Card.Body>
    </Card>
    
    </div>  

    <h1 className='eych2 text-center'>Main Service</h1>

    <div className='cards'>
    <Card className='card' style={{ width: '22rem' }}>
      <Card.Body>
        <Card.Title className='cardtitle text-center'>Holding Expos</Card.Title>
        <Card.Text  className='cardtext1'>
        Canadian Trade-ex are facilitators of Large Events. In producing the events we will act on behalf of ourselves or will work with Sector Stakeholders producing an event to their liking. We bring a qualified team that will produce results and meet deliverables and exceed expectations.
        </Card.Text>
      </Card.Body>
    </Card>
    </div>  
    <div className='cards'>
    <Card className='card' style={{ width: '22rem' }}>
      <Card.Body>
        <Card.Title className='cardtitle text-center'>Publishing</Card.Title>
        <Card.Text  className='cardtext1'>
        Canadian Trade-Ex has been producing our own publications for the last 20 years, our team in the process has developed expertise in the layout and production workflow of print content. We are now extending this expertise to the public and government sector. We will be introducing our team by participating in upcoming RFP’s for mid to large scale production runs.
        </Card.Text>
      </Card.Body>
    </Card>
    </div>  
    <div className='cards'>
    <Card className='card' style={{ width: '22rem' }}>
      <Card.Body>
        <Card.Title className='cardtitle text-center'>Conferences and Banquets</Card.Title>
        <Card.Text  className='cardtext1'>
        Conferences and banquets have become embedded within Trade-Shows providing value added services to the attendees. We have worked with organizations and stakeholders of the sector producing National Level conferences that have a unique flavour that recognizes our bottom up approach to content and attendee development.
        </Card.Text>
      </Card.Body>
    </Card>
    </div> 
    <div className='cards'>
    <Card className='card' style={{ width: '22rem' }}>
      <Card.Body>
        <Card.Title className='cardtitle text-center'>Host Your Own Event</Card.Title>
        <Card.Text  className='cardtext1'>
        Looking for mentoring support or Consulting services so that your own organization can host your own event. We can be involved to what ever level you choose. We can structure the layout, organize the set up and tear down, provide trade show accessories from 60" digital display to carpet. In addition to the traditional services we can introduce digital technolgies that include live broadcasting, and event software management. Give us a call we welcome the opportunity to partner.
        </Card.Text>
      </Card.Body>
    </Card>
    </div>  

    <h1 className='eych2 text-center px-4'>If you have some questions you can call 705-264-2251 and talk to</h1>
    
    <div className='allcontacts'>
      
    <p className='cardtitle1'>Glenn Dredhart President</p>
    <p className='cardtext1'>President of Canadian Trade-Ex (CT-EX), Publisher of Mining Life & Exploration News, President of mininglifeonline.net</p>
    <p className='cardtext1'>Glenn has recieved from The City of Timmins Economic Development and North Eastern Ontario Catholic School Board Certificates of Recognition for for his contribution to the Economic Development and Placement of students within the community.</p>
    

    <p className='cardtitle1'>Audrey Guindon Vice President</p>
    <p className='cardtext1'>Audrey over sees the administrative side of CT-EX operations. She works closely with Glenn ensuring that all of the assets that the company produces delivers the highest standards within the industry. She strives to continously improve and make each event, publication, conference better then the previous.</p>


    <p className='cardtitle1'>Rhonda Larochelle Sales Rep/Director Event Activity</p>
    <p className='cardtext1'>Rhonda is a sales representative and has been involved in this role for 18 years. When Rhonda is not serving her clients, she will be busy organizing many of the activities of our physical events from conferences to networking sessions,</p>

    <p className='cardtitle1'>John Goulet Sales Rep/Event Set Up Director</p>
    <p className='cardtext1'>John has a diverse background, he is a graduate of Northern College with a diploma in Business. John also has 15 years experince working the exploration drill rigs and working underground as a production miner. For the last 20 years, he has been a sales agent with Canadian Trade-Ex and oversees the preparation and tear down of all of our physical events.</p>

    </div>
    
    <a className='click text-center'><p>Click here to see who does all this stuff</p></a>

      </>
    )
}