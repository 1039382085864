import React from "react"
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function CenCanExpo(){
 return(
    <>
      <img src="/images/slide (1).png"></img>

    <h1 className='eych2 text-center'>Cen-Can Resource Expo</h1>

    <div className='frdiv'>
        <p>The Central Canada Resource Expo is a gathering place for all those who participate in the business of resources (Mining, Forestry or Energy). The expo will be showcasing the latest in resource technologies, expand the discussion of sustainable development with industry leaders and provide a forum that fosters economic regional growth. The Expo is an opportunity to network with experts, ask questions, and see the latest in technology and where the industries are going tomorrow. Cen-Can Resources Expo will be showcasing the richness of opportunities to the world Sept 13th -14th at the Fort William Gardens in Thunder Bay.</p>
      </div>

    <div className='frdiv'>
        <p>
        Plans are in the works to build on our successful 2023 event. We will be announcing dates and times for 2024 by Dec/2023.</p>
      </div>

      <div className='cards mt-5'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/box (1).png" />
    </Card>

    </div> 
   
      <div className='cards mt-5'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/box (1).jpg" />
    </Card>

    </div>
   

 </>
 )
} 