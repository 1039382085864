import React from "react"
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Conferences(){
 return(
    <>
    <h1 className='eych2 text-center'>Events</h1>

    <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/event.png" />
    </Card>

    </div>

    <div className='frdiv'>
        <p>Hundreds of rural communities with economies that are tied to the cyclical nature of natural resources are looking towards a new sustainable business model. The one common thread that is shared amongst these rural communities is the awe inspiring beauty and vastness of their outdoors.  Some communities have begun to leverage what God has given them and are introducing “Recreation” styled events that is driving business growth and jobs.</p>
      </div>

      <div className="px-3">
 
        <p><i>In the USA West, once booming timber towns, are working hard to rebrand themselves as “Adventure Destinations” , their adrenaline fix of choice is mountain biking.  Jeff McNamee a professor at Linfield College has documented the benefits of Adventure Events, namely mountain biking in 2 towns in the USA North West.  A typical 3 day event brings in $1million, and visitors from 5 countries and 28 states, totalling 1700.  The most surprising finding from McNamee’s research was the demographics of the visitors.</i></p>

        <p><i>“We tend to envision mountain bikers as young punks, but most visitors at these events were older adults with disposable incomes,” McNamee said. Almost three-quarters of the participants had incomes of $100,000 or higher, with 30 percent of all visitors making more than $200,000.</i></p>
        </div>      


        <div className='frdiv'>
        <p>Canadian Trade-Ex welcomes the opportunity of helping your community introduce an Event that leverages your best assets, and will attract a global audience.</p>
      </div>

      <div className='frdiv pb-4 pt-3'>
        <p>If you have any questions please do not hesitate to fill in the form below and a representative will call you shortly.</p>
      </div>

      <div className="card p-4 m-3">
      <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name </Form.Label>
        <Form.Control type="email" required/>
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" required/>
        <Form.Label>Telephone Number</Form.Label>
        <Form.Control type="email" placeholder="+1 (123) 456-7890" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
    </Form>

    <p>Services Interested. Check all services that you may are interested.</p>

    <Form>
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Conferences ${type}`}
            className="custom-label" 
          />
        </div>
      ))}
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Events ${type}`} className="custom-label" 
          />
        </div>
      ))}
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Set Up Events ${type}`} className="custom-label" 
          />
        </div>
      ))}
      {[''].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Consulting ${type}`} className="custom-label" 
          />
        </div>
      ))}
    </Form>
      
        <button className="btn1">Send</button>

    </div>

   <div className='cards mt-5'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/banner1.png" />
    </Card>

    </div>

   <div className='cards'>

    <Card style={{ width: '22rem' }}>
      <Card.Img variant="top" src="images/banner2.png" />
    </Card>

    </div>
 </>
 )
} 