
import MyNavbar from './components/MyNavbar';
import MyFooter from './components/MyFooter';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Whoweare from './pages/Whoweare';
import OurStaff from './pages/OurStaff';
import OurHistory from './pages/OurHistory';
import MiningShow from './pages/CanadianMiningExpo';
import Conferences from './pages/VirtualEvents';
import Events from './pages/Events';
import CenCanExpo from './pages/CenCanExpo';
import MiningPortal from './pages/MiningPortal';
import MiningMagazine from './pages/MiningMagazine';
import Consulting from './pages/Consulting';
import ContactUs from './pages/ContactUs';

import './App.css'


function App() {
  return (
    <Router scrollRestoration="auto">
      <MyNavbar/>
      <Routes>
          <Route path="/" element={<Home/>}/> 
          <Route path="/Whoweare" element={<Whoweare/>}/>
          <Route path="/OurStaff" element={<OurStaff/>}/>
          <Route path="/OurHistory" element={<OurHistory/>}/>
          <Route path="/MiningShow" element={<MiningShow/>}/>
          <Route path="/Conferences" element={<Conferences/>}/>
          <Route path="/Events" element={<Events/>}/>
          <Route path="/CenCanExpo" element={<CenCanExpo/>}/>
          <Route path="/MiningPortal" element={<MiningPortal/>}/>
          <Route path="/MiningMagazine" element={<MiningMagazine/>}/>
          <Route path="/Consulting" element={<Consulting/>}/>
          <Route path="/ContactUs" element={<ContactUs/>}/>
        </Routes>
      <MyFooter/>
    </Router>
  );
}

export default App;
